@import '../variables.scss';
.section {
    @media (max-width: #{$screen-md-max}) {
        h2{
            text-align: center;
        }
        >:global(.col-lg-3), >:global(.col-lg-9){
            padding:0
        }
    }
    a {
        color: black;
    }
    .search-form {
        line-height: 30px;
        display: table;
        margin-left: auto;
        margin-right: 0;
    }

    .search-input {
        display: table-cell;
        vertical-align: middle;
        width: 178px;
        height: 30px;
        font-size: 12px;
        padding: 7px;
        margin: 0;
        border: 1px solid #dcdcdc;
        color: #666666;
    }

    .search-button {
        display: table-cell;
        vertical-align: middle;
        content: ' ';
        width: 31px;
        height: 30px;
        background-image: url(./images/media/search_button.png);
        border: none;
        padding: 0;
    }
    

    :global(.card) {
        border-radius: 0 !important;
        :global(.row){
            > div:first-child{
                padding-right: 0;
                max-height: 12rem;
            }
        }
    }
    :global(.thumbnail-wrapper ) {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

    :global(.thumbnail ) {
        //border: 1px solid rgba(0, 0, 0, 0.125);
        position: relative; 
        padding-top: 100%;
        :global(.centered){
            position: absolute; top: 0; left: 0; right: 0; bottom: 0; -webkit-transform: translate(50%,50%); -ms-transform: translate(50%,50%); transform: translate(50%,50%);


        }
        img {
            position: absolute; top: 0; left: 0; max-width: 100%; height: auto; -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
            width: 100%;
                padding: 10px;
            //border: 1px solid rgba(0, 0, 0, 0.125);
        }

    }
}

    :global(.card-body) {
        position: relative;
        padding-left: 0;            
        height: 100%;
    }

    :global(.card-subtitle) {
        width: 100%;
        div {
     
        }
        .provider{
            position: absolute;
            bottom: 0.5rem;
            width: 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }        
        .date{
            position: absolute;
            bottom: 0.5rem;
            padding-right:0.5rem;
            right:0
        }
    }

    :global(.card-title) {

        font-weight: bold;
        width: 100%;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        @media (max-width: #{$screen-xs-min}){
            -webkit-line-clamp: 1;
        }
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

            position: absolute;
            top: 1rem;
            right: 0;
            padding-right: 1.25rem;

    }
}

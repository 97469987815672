@import '../variables.scss';

.section {
    background: url(./images/key_features/section_background.png) center;
}
.vertical_line{
        border-right: 1px solid white;
        height: 92px;
        width: 50%;
        position:relative
}

.features {
    height: max-content;
    .before {
        color: #bababa;
        text-decoration: line-through;
        margin-bottom: 1.5rem;
        
    }
    .after {
        p{ padding: 0 2.5rem; }

        @media (max-width: #{$screen-sm-max}){
            text-align: left;
            p{padding-left: 0;} 
        }
        @media (max-width: #{$screen-xs-max}){
            p{padding: 0;} 
        }
        h3{
        color: #0c3785;
        margin-bottom: 1rem;
        }
    }
    .text {
        margin-top: 54px;
    }
    .feature {
        background-color: white;
        padding: 3rem 0.5rem;
        margin: 0.5rem 0;
        @media (min-width: #{$screen-md-min}){
            height: 100%;
            padding: 2rem;
            padding-top: 3rem;
        }
        
    }
}
@import '../variables.scss';

.footer {
    :global(.section-container){
        padding: {
            top:0;
            bottom:1.5rem;
        };
    }
    .contact_text{
        color: #010101;
        font-weight: bolder;
    }
    .image_container{
            position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
          @media (max-width: #{$screen-xs-max}){
            img{
                width: 50%;
                float: right;
            }
        }
    }
}
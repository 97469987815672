$container-max-widths: (
  xl: 1250px
);

$navbar-brand-font-size: 2.458rem;
$navbar-light-color: black;
$navbar-light-active-color: #0c3785;
$navbar-light-hover-color: #0c3785;

.navbar-light .navbar-toggler{
  border: none
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  font-weight: bold;
  text-decoration: underline;
}


@import "~bootstrap/scss/bootstrap.scss";

.carousel-indicators {
  position: relative;
  
  margin: 0;
  margin-top: 50px;
  
  li {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 5px;
    background-color: white;
    opacity: 1;
  }

  .active {
    background-color: #82aeff;
  }
}
.container {
  padding: 0
}
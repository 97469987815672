@import '../variables.scss';

.section {
}

.section_upper {
  h3 {
    line-height: 2rem;
  }
  p {
    line-height: 2rem;
    font-weight: 700;
    line-height: 1.5em;
    width: 90%;
    @media (max-width: #{$screen-xs-max}) {
      margin-bottom: 1.5rem;
      width: 100%;
    }
    @media (min-width: #{$screen-lg-min}) {
      margin-bottom: 1.5rem;
      width: 80%;
    }
  }
}

.section_lower {
  h2 {
      margin-bottom: 4rem;
  }
  img {
    max-height: 220px;
    height: 100%;
  }
}

.feature {
  border: 1px solid #82aeff;
  margin: 2rem 4rem;
  padding: 5rem;
  &:first-child {
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 0
  }
  @media (max-width: #{$screen-sm-max}) {
    margin: 2rem 0;
    padding: 3rem 1.5rem;
  }
  @media (max-width: #{$screen-xs-max}) {
    text-align: center;
      &:last-child {
        padding-bottom: 1.5rem;
        
    }
  }
  .productBox {
    padding: 2.5rem;
    border: 1px solid #e1e1e1;
      @media (max-width: #{$screen-xs-max}) {
            padding: 2.5rem 1.5rem;
  }
    .content {
      h1 {
      }
    }

    .productName {
        margin-bottom: 1rem;
    }
    .productDescription {
        line-height: 1rem;
    }
    .tempisLogo {
      position: relative;
    width: 38px;
    height: 38px;
    display: inline-block;
    border-radius: 19px;
    border: 1px solid #3333;
    padding: 2px;
    margin-right: 1rem;
    background-color: #f7f9fa;
    }
  }
  .button {
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #024097;
    text-align: center;
    color: white !important;
    font-weight: bolder;
  }
}

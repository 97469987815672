@import '../variables.scss';

.section {
    padding: 5rem;
    @media (max-width: #{$screen-sm-max}) {
        margin: 0;
        padding: 0;
        h2{
            text-align: center;
        }
        >:global(.col-lg-3), >:global(.col-lg-9){
            padding:0
        }
  }
}
.tabGroup{
    display: block;
}
.tab {
    color: $primary-color !important;
    background-color: #fafafa !important;
    border: none;
    border-radius: 0 !important;
    margin-bottom: 0.5rem;
    display: inline-block;
    width: 100%;
    text-align: left;
    @media (max-width: #{$screen-sm-max}) {
        width: 50%;
        text-align: center;
        &:global(.active){
            background-color: $primary-color !important;
            color: #fefefe !important;
        }
  }
}

.tab-bullet:global(.active) {
    display: block;
    position: absolute;
    width: 25px;
    height: 1.5px;
    right:1.25rem;
    top: 50%;
    border-bottom: $primary-color 1px solid;
}

.faq-item-header {
    text-align: left;
    background-color: #fafafa !important;
    border-radius: 0 !important;
    border: none;
    padding: 0.75rem 1.25rem;
    font-weight: bold;
    color: #666666 !important;
    margin-bottom: 0.5rem;

    &:global(.active) {
        margin-bottom: 0px;
    }
    span{
        width: calc(100% - 21px);
        display:block
    }
}

.faq-item-header-caret {
    position: absolute;
    top: 0;
    height: 100%;
    width: 21px;
    right: 1.25rem;
    display: block;
    background: {
        repeat: no-repeat;
        position: center;
        image: url('./images/faq/caret-close.png');
    }
    &:global(.active) {
        background-image: url('./images/faq/caret-open.png');
    }
}

.faq-item-content {
    font-size: 14px;

    margin: {
        top: -9px;
        bottom: 30px;
    }

    border: {
        left: none;
        right: none;
        top: 1px solid $primary-color;
        bottom: 1px solid $primary-color;
        radius: 0;
    }
}
.pagination {
    font-size: 16.11px;

    a, button {
        border: none;
        color: #666666;
    }

    button {
        padding: 5px;
    }

    li {
        margin: {
            left: 5px;
            right: 5px;
        }
        button:hover{
            border-color: #1440dd !important;;
            color: #1440dd !important;
            background-color: white !important;
        }

        &:global(.active) button {
            border-color: #1440dd !important;;
            color: #1440dd !important;
            background-color: white !important;
        }
    }

    button {
        border-bottom: 2px solid #dcdcdc;
    }

    .first {
        color:#666666;
        font-weight: 900;
        &:global(.disabled){
            color:#dcdcdc;
        }
        //background: url(./images/pagination_prev.png) no-repeat center;
    }

    .last {
        color:#666666;
        font-weight: 900;
                &:global(.disabled){
            color:#dcdcdc;
        }
        //background: url(./images/pagination_next.png) no-repeat center;
    }
}
@import '../variables.scss';

.section {
    position: relative;
    padding-bottom: 37px;
    height: 800px;

    .background {
        content: " ";
        display: block;
        position: absolute;
        right: 0;
        width: 55%;
        height: 800px;
        z-index: -1;
        background-color: $primary-color;
        @media (max-width: #{$screen-xs-max}){
            width: 100%;   
            height: 600px;     
        }
    }
    .innerBox {
        position: absolute;
        top: 137px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 1200px;
        height: 450px;
        border: 15px solid #f7f7f7;
        padding-left: 3rem;
        @media (max-width: #{$screen-xs-max}){
            border: none;
            width: 100%;   
            top:0;  
            padding-left: 1.5rem;
        }
        z-index: -1;
        h1{
            width: 40%;
            @media (max-width: #{$screen-xs-max}){
                width: 50%;        
                color: white;
            }
            b{
                font-weight: 900;
            }
        }
    }
}

.carousel-content {
    width: 100%;
    height: 800px;
}

.carousel-left {
    width: 100%;
    max-width: 1200px;
    height: 600px;
    padding-left: 100px;
    margin-top: 76px;
    margin-left: auto;
    margin-right: auto;
    font-size: 50px;
    display: flex;
    align-items: center;
    .carousel-left-text {
        width: 50%;
        vertical-align: middle;
    }
}

.carousel-right {
    content: " ";
    display: inline-block;
    width: 100%;

    &.image {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background: {
            position-y: -70px;
            repeat: no-repeat;
        }
        @media (max-width: #{$screen-sm-min}){
            width: 70%;        
        }
    }

    @for $i from 1 through 3 {
        &.image#{$i} {
            background-image: url(./images/teaser/carousel#{$i}.png);
        }
    }
}

.carouselIndicator { 
    position: absolute;
    bottom: 25px;
    @media (max-width: #{$screen-xs-max}){
            bottom: 30%;
    justify-content: left;
    left: 1.5rem;
    }
}
@import "./bootstrap.scss";
@import '~@kfonts/nanum-square';
@import './variables.scss';
@import '~animate.css/animate.min.css';
.animated {
  height: 100%;
  width: 100%;
}
a:hover{
  text-decoration: none;
  font-weight: bolder;
}
body {
  font-family: 'NanumSquare', sans-serif;
  word-break: keep-all;
  font-size: 1.1rem;
}
p{
  margin: 0
}
h1 {
  font-size: 3rem;
  color: $navy-dark-color;
  @media (max-width: #{$screen-xs-max}){
    font-size: 2rem;
  }
}
h2 {
  font-weight: bolder;
  font-size: 2.2rem;
  color: $navy-light-color;
  margin-bottom: 1.5em;
}
h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
b{
      font-weight: 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
br{
  display: block;
  content: "";
  margin-top: 0.2em;
}
img{
  max-width: 100%;
}
.section-container{
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
    @media (max-width: #{$screen-sm-max}){
      padding-right: 1.5rem;
      padding-left: 1.5rem;
  }
}

.text{
  &-large{font-size: 1.25rem;}
  &-small{font-size: 1rem;}
  &-tiny{font-size: 0.77rem;}
}
.text{
  &-black{color: black;}
  &-navy-light{color: $navy-light-color;}
  &-navy{color: $navy-color;}
  &-navy-dark{color: $navy-dark-color;}
  &-gray-dark{color:#333333;}
  &-gray{color:#666666;}
  &-gray-light{color:#999999;}
}

.background-white {
  background-color: #ffffff;
}

.background-grey {
  background-color: #f4f6fa;
}

.background-darker-grey {
  background-color: #eaeef6;
}

.background-blue {
  background-color: $primary-color;
}



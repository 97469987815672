@import '../variables.scss';
.section {
}
.cardContainer{
    margin: 0.5rem 0;
      @media (max-width: #{$screen-xs-max}) {
          /*&:nth-child(odd){
            padding-right: 5px
          }
        &:nth-child(even){
            padding-left: 5px
          }*/
          
  }
}
.card {
    border: none;
    border-radius: 0 !important;

    :global(.card-img-top) {
        border-radius: 0 !important;
    }

    color: black;

    text-align: left;

    :global(.card-title) {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #0d0c3a;
    }

    :global(.card-subtitle) {
        font-weight: bold;
        color: white;
        background-color: #1a55c6;
        display:inline-block;
        padding: 0.3rem 0.5rem;
    }

    :global(.card-text) {
        margin-top: 0.75rem;
        color:#666666;
    }
}
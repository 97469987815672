.button {
    display: block;
    position: fixed;
    bottom: 95px;
    z-index: 999;
    right: 0;
    content: ' ';
    border: 0;
    width: 50px;
    height: 50px;
    background: url(./images/scroll_top_button.png);
}
@import '../variables.scss';
.section {
  position: relative;
  h2 {
    margin: {
      top: 5rem;
      bottom: 1rem;
    }
    @media (max-width: #{$screen-xs-max}) {
      margin-top: 0;
    }
  }
  :global(.carousel-indicators) {
    margin-top: 0;
  }
  ol.steps {
    margin-top: 55px;
    padding-left: 0;
    counter-reset: listCounter;

    li {
      list-style-type: none;
      margin-bottom: 10px;
      text-indent: -2.4rem;
      padding-left: 2.4rem;
      &::before {
        display: inline-block;
        counter-increment: listCounter;
        content: counter(listCounter);
        width: 1.8rem;
        height: 1.8rem;
        background-color: #999999;
        color: white;
        border-radius: 0.9rem;
        font-weight: 800;
        text-align: right;
        margin-right: 10px;
        font-size: 1.2rem;
        padding-right: 0.6rem;
      }
      &.active {
        color: black;
        font-weight: bolder;
      }

      &.active::before {
        background-color: #11418d;
      }
    }
  }
}

.carouselBackground {
  width: 425px;
}

%carousel-control {
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  top: calc(50% - 40px);
  z-index: 999;
}
.carousel-control {
  &-prev {
    @extend %carousel-control;
    left: 0;
  }
  &-next {
    @extend %carousel-control;
    right: 0;
  }
}

.tutorial {
}

.tutorial-item {
  cursor: pointer;
}

.maplus-logo {
  margin-top: 10rem;
  @media (max-width: #{$screen-xs-max}) {
    text-align: center;
  }
  @media (max-width: #{$screen-sm-max}) {
    margin-top: 3rem;
  }
}

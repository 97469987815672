$screen-xs-min: 576px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;

$primary-color: #11418d;
$navy-color: #0c3785;
$navy-light-color: #11418d;
$navy-dark-color: #0d0c3a;